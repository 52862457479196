import React, { useContext, useEffect, useState } from "react";
import { Calendar as CalendarC } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import CalendarDetail from "./CalendarDetail";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import moment from "moment";

export default function Calendar({
  type,
  selectedCalendarDay,
  setSelectedCalendarDay,
}) {
  const [openCalendarDetail, setOpenCalendarDetail] = useState(false);
  const {
    calendarCounts,
    getCalendarCounts,
    calendarDetail,
    getCalendarDateList,
  } = useContext(RestaurantContext);

  useEffect(() => {
    getCalendarCounts();
  }, []);

  const appointmentMap = calendarCounts.reduce((acc, item) => {
    acc[item.day] = item.count;
    return acc;
  }, {});

  const handleDateClick = (date) => {
    const dateString = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    setSelectedCalendarDay(moment(date).format("YYYY-MM-DD"));

    if (type === "task") {
      getCalendarDateList(dateString);
      setOpenCalendarDetail(true);
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dateString = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      if (appointmentMap[dateString]) {
        return (
          <div
            style={{
              cursor: "pointer",
              fontWeight: 600,
              display: "block",
              background: "#fff",
              borderRadius: 9999,
              width: "max-content",
              margin: "auto",
              padding: "5px 10px",
            }}
            className="appointment"
          >
            {appointmentMap[dateString]}
          </div>
        );
      }
    }
    return null;
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const dateString = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      const today = new Date();
      const isToday = moment(date).isSame(today, "day");
      const isPastDate = moment(date).isBefore(today, "day");
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      // Bugün olan gün
      if (isToday) {
        return "today";
      }

      // Geçmiş randevular
      if (appointmentMap[dateString] && isPastDate) {
        return "past-appointment";
      }

      // Hafta sonları
      if (isWeekend) {
        return "special-day";
      }

      // Gelecek randevular
      if (appointmentMap[dateString]) {
        return "future-appointment";
      }
    }
    return null;
  };

  return (
    <div style={{ width: "100%" }}>
      {type === "task" && (
        <div
          style={{
            margin: 10,
            letterSpacing: 0.8,
            fontFamily: "segoeuiB",
            color: "#fff",
          }}
        >
          Randevular
        </div>
      )}

      <CalendarC
        tileClassName={tileClassName}
        onClickDay={handleDateClick}
        tileContent={tileContent}
        locale="tr"
        style={{ width: "100%" }}
      />

      {calendarDetail.length > 0 && (
        <CalendarDetail
          openCalendarDetail={openCalendarDetail}
          setOpenCalendarDetail={setOpenCalendarDetail}
          calendarDetail={calendarDetail}
          selectedCalendarDay={selectedCalendarDay}
          setSelectedCalendarDay={setSelectedCalendarDay}
        />
      )}
    </div>
  );
}
